var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "d-flex justify-content-center", attrs: { app: "" } },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "secondary white--text pa-4",
          attrs: { width: "100%", fixed: "", height: "100vh" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "white", icon: "", large: "" },
                  on: {
                    click: function ($event) {
                      _vm.drawer = false
                    },
                  },
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.mdiWindowClose) +
                        "\n                    "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("a", { attrs: { href: "/" } }, [
                _c("span", { staticClass: "d-none" }, [
                  _vm._v(
                    "\n                        Menu\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: { height: "40", width: "305", src: "/logo-white.svg" },
                }),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-text-field", {
            attrs: {
              dark: "",
              placeholder: _vm.$t("searchWhat"),
              clearable: "",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                _vm.$router.push(
                  "/" + _vm.$t("searchSlug") + "?q=" + _vm.search
                )
              },
            },
            scopedSlots: _vm._u([
              {
                key: "prepend-inner",
                fn: function () {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      icon: "",
                                      disabled: !_vm.search,
                                      large: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.$router.push(
                                          "/" +
                                            _vm.$t("searchSlug") +
                                            "?q=" +
                                            _vm.search
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.mdiMagnify) +
                                          "\n                                "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.$t("search")) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dark: "" } },
            [
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      _vm.pageOverlay = "aboutUs"
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("aboutUs")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-list-group",
                {
                  attrs: { value: false, color: "white" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c("v-list-item", { staticClass: "pa-0" }, [
                            _vm._v(_vm._s(_vm.$t("categories"))),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _vm._l(_vm.categories, function (category, ci) {
                    return _c(
                      "v-list-item",
                      {
                        key: ci,
                        staticClass: "pl-8",
                        attrs: {
                          href: "/" + _vm.$t("categorySlug") + category.url,
                        },
                      },
                      [
                        _c(
                          "v-list-item-title",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.alert("Clicked!" + ci)
                              },
                            },
                          },
                          [_vm._v(_vm._s(category.title))]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      _vm.pageOverlay = "contact"
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("contact")) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        {
          staticClass: "green-bg font-weight-bold",
          attrs: {
            height: "68",
            app: "",
            flat: "",
            "elevate-on-scroll": "",
            "hide-on-scroll": "",
            elevation: "5",
          },
          scopedSlots: _vm._u(
            [
              _vm.$vuetify.breakpoint.lgAndUp
                ? {
                    key: "extension",
                    fn: function () {
                      return [
                        _c(
                          "v-container",
                          { staticClass: "hidden-md-and-down" },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass: "d-flex justify-center green-bg",
                                attrs: { flat: "" },
                              },
                              [
                                _vm._l(
                                  _vm.featuredCategories,
                                  function (category, ci) {
                                    return _c(
                                      "v-btn",
                                      {
                                        key: ci,
                                        staticClass: "mx-4 text-capitalize",
                                        attrs: {
                                          large: "",
                                          text: "",
                                          href:
                                            "/" +
                                            _vm.$t("categorySlug") +
                                            _vm.categories[_vm.map[category]]
                                              .url,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.categories[_vm.map[category]]
                                                .title
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-4 text-capitalize",
                                    attrs: {
                                      id: "show_all",
                                      large: "",
                                      text: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.$t("viewAll")) +
                                        "\n                            "
                                    ),
                                    _c("v-icon", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.mdiChevronDown) +
                                          "\n                            "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      "offset-y": "",
                                      "content-class":
                                        "left-initial pa-4 pt-0 elevation-0",
                                      activator: "#show_all",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      {
                                        staticClass:
                                          "green-bg elevation-5 pt-0 pb-4",
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { "no-gutters": "" } },
                                          _vm._l(
                                            _vm.categories,
                                            function (item, categoryIndex) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: categoryIndex,
                                                  staticClass: "px-2",
                                                  attrs: { cols: "6", md: "4" },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "font-weight-bold mt-5 px-8 text-capitalize",
                                                      attrs: {
                                                        text: "",
                                                        outlined: "",
                                                        large: "",
                                                        block: "",
                                                        color: "primary",
                                                        href:
                                                          "/" +
                                                          _vm.$t(
                                                            "categorySlug"
                                                          ) +
                                                          item.url,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            " +
                                                          _vm._s(item.title) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "d-lg-none",
            on: {
              click: function ($event) {
                _vm.drawer = true
              },
            },
          }),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            { staticClass: "d-none d-lg-flex" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-bold",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.pageOverlay = "aboutUs"
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("aboutUs")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-bold",
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      _vm.pageOverlay = "contact"
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("contact")) +
                      "\n                "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-toolbar-title", [
            _c("a", { attrs: { href: "/" } }, [
              _c("span", { staticClass: "d-none" }, [
                _vm._v("\n                        Menu\n                    "),
              ]),
              _vm._v(" "),
              _c("img", {
                attrs: { height: "40", width: "305", src: "/logo.svg" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            { staticClass: "d-none d-lg-flex" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-bold",
                  attrs: { text: "", id: "search_toggle" },
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.mdiMagnify) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("search")) +
                      "\n                "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: {
                    left: "",
                    top: "",
                    "offset-x": "",
                    "content-class": "pa-0 ma-0 mt-n4 elevation-0",
                    "close-on-content-click": false,
                    activator: "#search_toggle",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "overflow-hidden px-2 pb-4 pt-0",
                      attrs: { width: "300px", tile: "" },
                    },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-n8 elevation-0 transparent",
                        attrs: {
                          placeholder: _vm.$t("searchWhat"),
                          clearable: "",
                        },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            _vm.$router.push(
                              "/" + _vm.$t("searchSlug") + "?q=" + _vm.search
                            )
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend-inner",
                            fn: function () {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "mt-0",
                                                attrs: {
                                                  icon: "",
                                                  disabled: !_vm.search,
                                                  large: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.$router.push(
                                                      "/" +
                                                        _vm.$t("searchSlug") +
                                                        "?q=" +
                                                        _vm.search
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    "\n                                                " +
                                                      _vm._s(_vm.mdiMagnify) +
                                                      "\n                                            "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("search")) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4 text-capitalize",
                  attrs: { id: "countries", large: "", text: "" },
                },
                [
                  _c("flags", { attrs: { value: _vm.currentLocale } }),
                  _vm._v(" "),
                  _c("v-icon", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.mdiChevronDown) +
                        "\n                    "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-menu",
                {
                  attrs: {
                    right: "",
                    "offset-y": "",
                    "content-class": "pa-0 elevation-0 green-bg",
                    activator: "#countries",
                  },
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "transparent pa-0" },
                    _vm._l(_vm.otherLocales, function (locale, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i + locale,
                          staticClass: "transparent",
                          attrs: { disabled: "", href: "#" },
                        },
                        [
                          _c("flags", { attrs: { value: locale } }),
                          _vm._v(" "),
                          _c("span", { staticClass: "pl-3" }, [
                            _vm._v(_vm._s(locale.toUpperCase())),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-main", [_c("Nuxt")], 1),
      _vm._v(" "),
      _c(
        "v-footer",
        { staticClass: "secondary white--text" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            lg: "6",
                            order: "3",
                            "order-lg": "1",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "transparent", attrs: { flat: "" } },
                            [
                              _c("a", { attrs: { href: "/" } }, [
                                _c("img", {
                                  staticClass: "mb-3",
                                  attrs: {
                                    height: "40",
                                    width: "305",
                                    src: "/logo-white.svg",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "white--text font-weight-light pl-0 pr-lg-7",
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(_vm.$t("footer.disclaimer")) +
                                      "\n                                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "6",
                            lg: "3",
                            "order-lg": "2",
                            order: "1",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "pl-4 white--text text-uppercase text-h5",
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("categories")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { staticClass: "transparent" },
                            _vm._l(
                              _vm.footerCategories,
                              function (category, ic) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: ic,
                                    staticClass: "white--text",
                                    attrs: {
                                      link: "",
                                      href:
                                        "/" +
                                        _vm.$t("categorySlug") +
                                        _vm.categories[_vm.map[category]].url,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                        " +
                                        _vm._s(
                                          _vm.categories[_vm.map[category]]
                                            .title
                                        ) +
                                        "\n                                    "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "6",
                            lg: "3",
                            "order-lg": "2",
                            order: "1",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "pl-4 white--text text-uppercase text-h5",
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("links")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list",
                            { staticClass: "transparent" },
                            _vm._l(_vm.footerLinks, function (link, il) {
                              return _c(
                                "v-list-item",
                                {
                                  key: il,
                                  staticClass: "white--text",
                                  attrs: { link: "", href: "/" + link.slug },
                                },
                                [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(link.title) +
                                      "\n                                    "
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider", { staticClass: "white my-6" }),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "transparent text-center px-lg-16 mx-lg-16 white--text",
                              attrs: { flat: "" },
                            },
                            [
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(_vm.$t("footer.copyright")) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-overlay", {
        on: {
          close: function ($event) {
            _vm.pageOverlay = ""
          },
        },
        model: {
          value: _vm.pageOverlay,
          callback: function ($$v) {
            _vm.pageOverlay = $$v
          },
          expression: "pageOverlay",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }