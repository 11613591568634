const map =  {
    de: {
        auto_und_motorrad: 'car',
        baby_und_spielwaren: 'baby',
        gesundheit_und_kosmetik: 'beauty',
        computer_und_software: 'laptop',
        heimwerken_und_garten: 'home',
        unterhaltungselektronik: 'electronic',
        lebensmittel_und_getranke: 'gourmet',
        handy_und_telefon: 'phones',
        haushalt: 'house',
        mode_und_schuhe: 'apparel',
        freizeit_und_musik: 'hobby',
        sport_und_outdoor: 'sports',
        fotografie: 'photography',
        wohnen_und_lifestyle: 'kitchen',
    },
    en: {
        auto_und_motorrad: 'car',
        baby_und_kind: 'baby',
        beauty_und_gesundheit: 'beauty',
        computer_und_software: 'laptop',
        haus_und_garten: 'home',
        elektronik_und_foto: 'electronic',
        gaming_und_splielen: 'toys',
        essen_und_trinken: 'gourmet',
        handy_telefon_und_fax: 'phones',
        heimwerken_und_werkzeuge: 'tools',
        kuche_und_haushalt: 'kitchen',
        mode_und_accessoires: 'apparel',
        erholung_und_hobby: 'hobby',
        sport_und_outdoor: 'sports',
        tierbedarf: 'pets',
    },
    es: {
        auto_und_motorrad: 'car',
        baby_und_kind: 'baby',
        beauty_und_gesundheit: 'beauty',
        computer_und_software: 'laptop',
        haus_und_garten: 'home',
        elektronik_und_foto: 'electronic',
        gaming_und_splielen: 'toys',
        essen_und_trinken: 'gourmet',
        handy_telefon_und_fax: 'phones',
        heimwerken_und_werkzeuge: 'tools',
        kuche_und_haushalt: 'kitchen',
        mode_und_accessoires: 'apparel',
        erholung_und_hobby: 'hobby',
        sport_und_outdoor: 'sports',
        tierbedarf: 'pets',
    },
    fr: {
        auto_und_motorrad: 'car',
        baby_und_kind: 'baby',
        beauty_und_gesundheit: 'beauty',
        computer_und_software: 'laptop',
        haus_und_garten: 'home',
        elektronik_und_foto: 'electronic',
        gaming_und_splielen: 'toys',
        essen_und_trinken: 'gourmet',
        handy_telefon_und_fax: 'phones',
        heimwerken_und_werkzeuge: 'tools',
        kuche_und_haushalt: 'kitchen',
        mode_und_accessoires: 'apparel',
        erholung_und_hobby: 'hobby',
        sport_und_outdoor: 'sports',
        tierbedarf: 'pets',
    },
    it: {
        auto_und_motorrad: 'car',
        baby_und_kind: 'baby',
        beauty_und_gesundheit: 'beauty',
        computer_und_software: 'laptop',
        haus_und_garten: 'home',
        elektronik_und_foto: 'electronic',
        gaming_und_splielen: 'toys',
        essen_und_trinken: 'gourmet',
        handy_telefon_und_fax: 'phones',
        heimwerken_und_werkzeuge: 'tools',
        kuche_und_haushalt: 'kitchen',
        mode_und_accessoires: 'apparel',
        erholung_und_hobby: 'hobby',
        sport_und_outdoor: 'sports',
        tierbedarf: 'pets',
    },
}

export default map;

// import like this
// import map from "~/config/category-map"