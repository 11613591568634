export default {
    welcome: 'Wilkommen',
    aboutUs: "Über uns",
    aboutUsPage: '    <p>\n' +
        '        Im Jahr 2015 sind wir mit einer Mission gestartet: Unseren Nutzerinnen und Nutzern dabei zu helfen, die bestmögliche Kaufentscheidung zu treffen. Heute zählt die noctemque UG mit seinen 11 Konsumenten-Portalen über 10 Millionen Besuchen pro Monat, rund 50.000 Shops und über 200 Millionen Angeboten zu den reichweitenstärksten deutschen E-Commerce-Betreiber. Auch international sind wir erfolgreich.\n' +
        '    </p>\n' +
        '    <p>\n' +
        '        Mit unserem Preisvergleichsportal <a href="https://www.dealsfinder.com">dealsfinder.com</a>  sind wir aktuell in sechs europäischen Ländern und den USA vertreten. <a href="https://www.dealsfinder.com">dealsfinder.com</a>  legt besonderen Wert auf "grünes", ressourcenschonendes und umweltverträgliches Shopping. Für den gesamten Lifetime-Cycles eines Produktes.\n' +
        '    </p>\n' +
        '    <p>\n' +
        '        Verantwortungsvoller Konsum und umweltbewusstes Leben können Hand in Hand gehen. Wir wollen unseren Konsumentinnen und Konsumenten dabei unterstützen die bestmöglichen Entscheidungen zu treffen.\n' +
        '    </p>\n' +
        '    <ul class="mb-4">\n' +
        '        <li>\n' +
        '            50.000 HändlerInnen\n' +
        '        </li>\n' +
        '        <li>\n' +
        '            > 10 Mio. Besucher im Monat\n' +
        '        </li>\n' +
        '        <li>\n' +
        '            > 200 Mio. Angebote\n' +
        '        </li>\n' +
        '    </ul>\n' +
        '    <h2 class="mb-4">\n' +
        '        Unser Versprechen:\n' +
        '    </h2>\n' +
        '    <h3 class="mb-4">\n' +
        '        Unabhängig\n' +
        '    </h3>\n' +
        '    <p>\n' +
        '        Eine Top-Platzierung ist bei <a href="https://www.dealsfinder.com">dealsfinder.com</a>  nicht käuflich.\n' +
        '    </p>\n' +
        '    <h3>\n' +
        '        Neutral\n' +
        '    </h3>\n' +
        '    <p>\n' +
        '        Wir verkaufen nichts, sondern informieren VerbraucherInnen über die Produkte und Angebote im Online-Handel. Bei <a href="https://www.dealsfinder.com">dealsfinder.com</a>  kann sich jede und jeder kompetent beraten lassen, ohne zu einem Kauf gedrängt zu werden.\n' +
        '    </p>\n' +
        '    <h3>\n' +
        '        Transparent\n' +
        '    </h3>\n' +
        '    <p>\n' +
        '        Wir helfen unseren NutzerInnen dabei, den oft undurchsichtigen Online-Handel besser zu durchdringen. Die Verwendung von <a href="https://www.dealsfinder.com">dealsfinder.com</a>  ist für alle VerbraucherInnen komplett kostenlos.\n' +
        '    </p>',
    search: "Suche...",
    currencySymbol: "€",
    viewAll: "Alle Kategorien anzeigen",
    close: "Schließen",
    resetFilter: "Filter zurücksetzen\n",
    cancel: "Absagen",
    clear: "Löschen",
    apply: "Anwenden",
    connect: "Verbinden",
    contact: "Kontakt / Impressum",
    contactPage: '    <p>\n' +
        '        noctemque UG (haftungsbeschränkt) <br>\n' +
        '        Camp-King-Allee 17 <br>\n' +
        '        61440 Oberursel <br>\n' +
        '        Germany <br>\n' +
        '        Tel: <a href="tel:00-49-170-216-1111">+49 (0)1702161111</a> <br>\n' +
        '        <a href="https://www.noctemque.com">www.noctemque.com</a> <br>\n' +
        '        E-Mail: <a href="mailto:partners@noctemque.com">partners@noctemque.com</a> <br>\n' +
        '        Registergericht: Bad Homburg v. d. Höhe <br>\n' +
        '        Registernummer: HRB 13202 <br>\n' +
        '        VAT identification number: DE300159417 <br>\n' +
        '        Vertreten durch: Michael Reifenrath <br>\n' +
        '        ------------------------VERANTWORTLICH FÜR DEN INHALT NACH § 55 <br>\n' +
        '        ABS. 2 RSTV: noctemque UG (haftungsbeschränkt) <br>\n' +
        '    </p>\n' +
        '    <p>\n' +
        '        STREITSCHLICHTUNG <br>\n' +
        '        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.\n' +
        '    </p>\n' +
        '    <p>\n' +
        '        HAFTUNG FÜR INHALTE <br>\n' +
        '        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.\n' +
        '    </p>\n' +
        '    <p>\n' +
        '        HAFTUNG FÜR LINKS <br>\n' +
        '        Unser Angebot enthält Links zu externen Webseiten Dritter, auf  deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.\n' +
        '    </p>\n' +
        '    <p>\n' +
        '        URHEBERRECHT <br>\n' +
        '        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.\n' +
        '    </p>\n' +
        '    <p>\n' +
        '        Unterstützt durch eRecht24\n' +
        '    </p>',
    categories: "Kategorien",
    categorySlug: "kategorie",
    searchSlug: "suche",
    links: "Links",
    shop: "Shop",
    sortItems: [
        {
            text: "Relevanz",
            value: null,
        },
        {
            text: "Preis steigend",
            value: '+price',
        },
        {
            text: "Preis absteigend",
            value: '-price',
        },
    ],
    shipping: "Lieferkosten",
    shippingTime: "Laufzeit",
    filter: "Filtern",
    searchWhat: "Was möchten Sie suchen?",
    product: {
        seeOffer: "Angebot ansehen",
        moreDetails: "Weitere Details",
        toShop: "Zum Shop",
        comparePrice: "Preise Vergleichen",
        from: "ab",
        overlay: {
            offerOther1: "Weitere Preise von",
            offerOther2: "anderer Shops",
            relatedOther1: "Kunde, die diesen Artikel haben, ",
            relatedOther2: "haben auch angesehen",
            noOffersAvailable: "Derzeit sind keine Angebote verfügbar",
        },
    },
    home: {
        hero: {
            title: "Suchen. Vergleichen. Sparen.",
            description: "Finde Deine Lieblingsstücke und die besten Deals im Netz. Wir durchforsten für Dich die Top 50.000 Shops Deutschlands.",
        },
        featuredCategories: [
            'apparel',
            'baby',
            'beauty',
            'laptop',
            'home',
            'gourmet',
        ],
        popular: [
            {
                name: 'Wintermäntel',
                image: '/img/pop.1.webp',
                url: '/suche?q=Wintermäntel',
            },
            {
                name: 'Möbel',
                image: '/img/pop.2.webp',
                url: '/suche?q=Möbel',
            },
            {
                name: 'Smartphones',
                image: '/img/pop.3.webp',
                url: '/suche?q=Smartphones',
            },
            {
                name: 'Hautpflege',
                image: '/img/pop.4.webp',
                url: '/suche?q=Hautpflege',
            },
            {
                name: 'Spaßgarantie',
                image: '/img/pop.5.webp',
                url: '/suche?q=Spaß',
            },
            {
                name: 'Kaffeemaschinen',
                image: '/img/pop.6.webp',
                url: '/suche?q=Kaffeemaschinen',
            },
        ],
        slides: [
            {
                title: "Grün. Verantwortlich. Umweltbewusst",
                description: "Beim Einkauf die grüne Entscheidung zu treffen war noch nie so einfach",
                image: 'home_slide_1.webp',
                cta: {
                    text: "Grün Shoppen",
                    url: '/suche?q=fsc&f_categories=103332,11302&m_p=3&m_ipp=12'
                },
            },
            {
                title: "Elegant? Modern? Alternativ? Klassisch?",
                description: "Für jede Frau die richtige Wahl. Damenmode bei den besten Shops.",
                image: 'home_slide_2.webp',
                cta: {
                    text: "Shoppen",
                    url: '/suche?q=damenmode&f_categories=104732&f_filter%3A212=51127&m_p=1&m_ipp=12'
                },
            },
            {
                title: "House + Love = Home",
                description: "Zu|hau|se:\n" +
                    "Ort, an dem das Herz wohnt;\n" +
                    "der allerschönste Ort auf der ganzen Welt.",
                image: 'home_slide_3.webp',
                cta: {
                    text: "Schöner Leben",
                    url: '/suche?q=wohnzimmer&f_price=200..14000&m_p=1&m_ipp=12'
                },
            },
            {
                title: "Männermode – Du bist einmalig – Zeig es auch",
                description: "Stil ist ein Weg, zu sagen, wer man ist – ohne sprechen zu müssen.",
                image: 'home_slide_4.webp',
                cta: {
                    text: "Finde Dein Stil",
                    url: '/kategorie/mode_und_schuhe?f_categories=29389&m_p=1&m_ipp=12'
                },
            },
            {
                title: "Wir sind ein Team: Mein Haustier und ich",
                description: "Niemals fand ich Menschenliebe,\n" +
                    "wo keine Tierliebe war.",
                image: 'home_slide_5.webp',
                cta: {
                    text: "Schönes Kaufen",
                    url: '/kategorie/heimwerken_und_garten?f_categories=14850&m_p=1&m_ipp=12'
                },
            },
        ],
        categories: {
            title: {
                text: "Unsere Themenwelt im Oktober",
                cta: {
                    text: "Shopping et Voyages",
                    href: "/",
                },
            },
            subtitle: "Die kalte Jahreszeit kann kommen",
            features: [
                {
                    image: "image1.webp",
                    title: "Wintermäntel",
                    url: "/suche/wintermäntel"
                },
                {
                    image: "image2.webp",
                    title: "Möbel",
                    url: "/suche/möbel"
                },
                {
                    image: "image3.webp",
                    title: "Smartphones",
                    url: "/suche/smartphones"
                },
                {
                    image: "image4.webp",
                    title: "Hautpflege",
                    url: "/suche/hautpflege"
                },
                {
                    image: "image5.webp",
                    title: "Spaßgarantie",
                    url: "/suche/spaßgarantie"
                },
                {
                    image: "image6.webp",
                    title: "Kaffeemaschinen",
                    url: "/suche/kaffeemaschinen"
                },

            ],
        },
        allCategories: {
            title: "Alle Kategorien",
            subtitle: "Entdecken Sie alle unsere Kategorien!",
        },
        products: {
            title: "Beliebteste Produkte",
            subtitle: "Entdecken Sie alle unsere Produkte!",
        },
    },

    searchPage: {
        resultsFound: "Ergebnisse gefunden.",
        searchedFor: "Gesucht nach ",
    },

    footer: {
        disclaimer: "Aufgrund technischer Einschränkungen können wir die vollständige Richtigkeit oder Vollständigkeit der von Händlern bereitgestellten Informationen nicht garantieren Website des Händlers ist letzteres maßgebend. Die angezeigten Preise verstehen sich inklusive aller Kosten, mit Ausnahme von Neufahrzeugen.",
        copyright: "Copyright © 2022 DealsFinder. Alle Rechte vorbehalten.",
        links: {
            aboutUs: {
                title: 'Über uns',
                slug: 'uberuns'
            },
            legal: {
                title: 'Impressum',
                slug: 'impressum'
            },
            privacy: {
                title: 'Datenschutz',
                slug: 'datenschutz'
            },
            contact: {
                title: 'Kontakt',
                slug: 'kontakt'
            },
        }
    },

    allCategories: {
        car: {
            title: "Auto & Motorrad",
            description: "Alles was das Herz begehrt für unsere motorisierten Lieblinge.",
            image: "auto_en.png",
            home_images: [ "hc.1.lg.webp", "hc.1.md.webp", "hc.1.sm.webp",],
            hero_url: '/kategorie/auto_und_motorrad',
            url: "/auto_und_motorrad",
            name: "auto_und_motorrad",
        },
        baby: {
            title: "Baby & Spielwaren",
            description: "Kleidungen für unsere Liebste: Von Naturmaterialien über Farbenfroh bis hin zu Funktionskleidung. Hier wird gefunden.",
            image: "home_en.webp",
            home_images: [ "hc.2.lg.webp", "hc.2.md.webp", "hc.2.sm.webp",],
            hero_url: '/kategorie/baby_und_spielwaren',
            url: "/baby_und_spielwaren",
            name: "baby_und_spielwaren",
        },
        beauty: {
            title: "Gesundheit & Kosmetik",
            description: "An meine Haut lass ich nur das Beste. Hier Deutschlands breite Produktpalette.",
            image: "health_en.webp",
            home_images: [ "hc.3.lg.webp", "hc.3.md.webp", "hc.3.sm.webp",],
            hero_url: '/kategorie/gesundheit_und_kosmetik',
            url: "/gesundheit_und_kosmetik",
            name: "gesundheit_und_kosmetik",
        },
        laptop: {
            title: "Computer & Software",
            description: "IT for All. Vom Zocker bis zum Business Profi.",
            image: "health_en.webp",
            home_images: [ "hc.21.lg.webp", "hc.21.md.webp", "hc.21.sm.webp",],
            hero_url: '/kategorie/computer_und_software',
            url: "/computer_und_software",
            name: "computer_und_software",
        },
        home: {
            title: "Heimwerken & Garten",
            description: "Mein Zuhause und mein Garten gestalten. Mit den schönsten Angeboten aus tausenden von Shops.",
            image: "home_en.webp",
            home_images: [ "hc.5.lg.webp", "hc.5.md.webp", "hc.5.sm.webp",],
            hero_url: '/kategorie/heimwerken_und_garten',
            url: "/heimwerken_und_garten",
            name: "heimwerken_und_garten",
        },
        gourmet: {
            title: "Lebensmittel & Getränke",
            description: "Von Gourmet bis Gutbürgerlich. Oder doch lieber eine kulinarische Reise um die Welt.",
            image: "appliances_en.webp",
            home_images: [ "hc.8.lg.webp", "hc.8.md.webp", "hc.8.sm.webp",],
            hero_url: '/kategorie/lebensmittel_und_getranke',
            url: "/lebensmittel_und_getranke",
            name: "lebensmittel_und_getranke",
        },
        apparel: {
            title: "Mode & Schuhe",
            description: "Stil ist ein Weg, zu sagen, wer man ist – ohne sprechen zu müssen.",
            image: "fashion_en.webp",
            home_images: [ "hc.12.lg.webp", "hc.12.md.webp", "hc.12.sm.webp",],
            hero_url: '/kategorie/mode_und_schuhe',
            url: "/mode_und_schuhe",
            name: "mode_und_schuhe",
        },
        sports: {
            title: "Sport & Outdoor",
            description: "Für alle unsere Sportarten, unsere Freizeit und unsere Outdoor-Aktivitäten hier die beste Angebote finden.",
            image: "fashion_en.webp",
            home_images: [ "hc.14.lg.webp", "hc.14.md.webp", "hc.14.sm.webp",],
            hero_url: '/kategorie/sport_und_outdoor',
            url: "/sport_und_outdoor",
            name: "sport_und_outdoor",
        },
        photography: {
            title: "Fotografie",
            description: "Unvergessliche Momente und Stimmung für immer Einfangen",
            image: "living_en.webp",
            home_images: [ "hc.16.lg.webp", "hc.16.md.webp", "hc.16.sm.webp",],
            hero_url: '/kategorie/fotografie',
            url: "/fotografie",
            name: "fotografie",
        },
        hobby: {
            title: "Freizeit & Musik",
            description: "Berausche Deine Sinne, Erlebe Freizeit in einer ganz neuen Dimension.",
            image: "fashion_en.webp",
            home_images: [ "hc.17.lg.webp", "hc.17.md.webp", "hc.17.sm.webp",],
            hero_url: '/kategorie/freizeit_und_musik',
            url: "/freizeit_und_musik",
            name: "freizeit_und_musik",
        },
        phones: {
            title: "Handy & Telefon",
            description: "Leben ist Kommunikation. Die besten Begleiter findest Du hier.",
            image: "phones_en.png",
            home_images: [ "hc.18.lg.webp", "hc.18.md.webp", "hc.18.sm.webp",],
            hero_url: '/kategorie/handy_und_telefon',
            url: "/handy_und_telefon",
            name: "handy_und_telefon",
        },
        electronic: {
            title: "Unterhaltungselektronik",
            description: "Elektronik für den heimischen Freizeit-Genuss. Hier die neuesten Trends.",
            image: "appliances_en.webp",
            home_images: [ "hc.19.lg.webp", "hc.19.md.webp", "hc.19.sm.webp",],
            hero_url: '/kategorie/unterhaltungselektronik',
            url: "/unterhaltungselektronik",
            name: "unterhaltungselektronik",
        },
        kitchen: {
            title: "Wohnen & Lifestyle",
            description: "Immer noch IKEA? Hier neue Lifestyle-Trends und Wohnideen finden.",
            image: "food_en.png",
            home_images: [ "hc.20.lg.webp", "hc.20.md.webp", "hc.20.sm.webp",],
            hero_url: '/kategorie/wohnen_und_lifestyle',
            url: "/wohnen_und_lifestyle",
            name: "wohnen_und_lifestyle",
        },
        house: {
            title: "Haushalt",
            description: "Küche, Haushalt und Dein Zuhause auf die schönste Art erleben. Tolle Angebote.",
            image: "hifi_en.png",
            home_images: [ "hc.21.lg.webp", "hc.21.md.webp", "hc.21.sm.webp",],
            hero_url: '/kategorie/haushalt',
            url: "/haushalt",
            name: "haushalt",
        },
    },

    featuredCategories: [
        'auto_und_motorrad',
        'baby_und_spielwaren',
        'gesundheit_und_kosmetik',
        'computer_und_software',
        'haushalt',
    ],
    footerCategories: [
        'gesundheit_und_kosmetik',
        'auto_und_motorrad',
        'computer_und_software',
        'heimwerken_und_garten',
    ],


}