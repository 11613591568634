export interface TopProduct {
    name:           string;
    description:    string;
    price:          string;
    price_per_unit: string;
    shipping_cost:  string;
    image:          string;
    sales_rank:     string;
    url:            string;
    url_adjusted:   string;
    categories:     string[];
    asin:           string;
}

export interface SearchProduct {
    title:             string;
    description:       string;
    price:             number | string;
    price_original:    number | string;
    unit_price?:       string;
    rebate_percentage: number | string;
    shipping:          number | string;
    total_price:       number | string;
    currency:          string;
    image_large:       string;
    brand:             string;
    category_id:       number | string;
    cpc:               number | string;
    mobile_cpc:        number | string;
    clickout_url:      string;
    source:            Source;
    price_per_unit?:   string;
}

export interface Category {
    brands: string[];
    description: string;
    images: string[];
    products: SearchProduct[];
}

export enum Source {
    Amazon = "amazon",
    Kelkoo = "kelkoo",
}

export interface RangeSelectedFilter {
    range: [number | string, number | string],
    min: number | string,
    max: number | string,
    selected: boolean,
}

export interface SelectedFilters {
    brand?: string[],
    categories?: string[],
    shop?: string[],
    ['filter:price']?: {
        range: [number, number],
        min: number,
        max: number,
        selected: boolean,
    },
    [key: string]: string[] | RangeSelectedFilter,
}

export interface RequestData {
    meta:               Meta;
    products:           Product[];
    availableFilters:   AvailableFilter[];
    term:               null | string;
    category?:          string[];
    images?:            string[];
    categoryFilters?:   CategoryFilters;
}

export interface AvailableFilter {
    description:            string;
    filter_parameter:       string;
    id:                     string;
    is_range_selectable:    boolean;
    selected:               boolean;
    values:                 AvailableFilterValue[];
    trimmed:                number;
    valueCount:             number;
}

export interface AvailableFilterValue {
    count:            number;
    description:      string;
    id:               string;
    numeric_sort_key: number | null;
    selected:         boolean;
    value_parameter:  string;
}

export interface Meta {
    total:          number;
    offset:         number;
    page:           number;
    itemsPerPage:   number;
}

export interface Product {
    brand:                         Brand;
    category:                      Category;
    description:                   string;
    estimated_total_offers_count?: number;
    external_product_ids:          ExternalProductIDS;
    id:                            number;
    images:                        Image[];
    legal_fields:                  any[];
    name:                          string;
    payment_methods?:              Method[];
    price:                         Price;
    relevance:                     number;
    shipping_methods?:             Method[];
    test_reports?:                 TestReports;
    type:                          ProductType;
    user_reviews?:                 TestReports;
    clickout_url?:                 string;
    location_countries?:           string[];
    main_image?:                   string;
    marketplace?:                  Marketplace;
    price_info?:                   PriceInfo;
    product_id?:                   null;
    promo_text?:                   string;
    shipping?:                     Shipping;
    shop?:                         Shop;
    voucher?:                      Voucher;
    offers?:                       ProductOffer[];
}

export interface ProductOffer {
    brand:                         Brand;
    category:                      Category;
    description:                   string;
    estimated_total_offers_count?: number;
    external_product_ids:          ExternalProductIDS;
    id:                            number;
    images:                        Image[];
    legal_fields:                  any[];
    name:                          string;
    payment_methods?:              Method[];
    price:                         Price;
    relevance:                     number;
    shipping_methods?:             Method[];
    test_reports?:                 TestReports;
    type:                          ProductType.Offer;
    user_reviews?:                 TestReports;
    clickout_url?:                 string;
    location_countries?:           string[];
    main_image?:                   string;
    marketplace?:                  Marketplace;
    price_info?:                   PriceInfo;
    product_id?:                   null|number;
    promo_text?:                   string;
    shipping?:                     Shipping;
    shop?:                         Shop;
    voucher?:                      Voucher;
}

export interface Brand {
    id:       number;
    logo_url: string;
    name:     string;
}

export interface Category {
    description:    CategoryDescription;
    document_types: ProductType[];
    id:             number;
    image_url:      string;
    is_leaf:        boolean;
    parent_id:      number | null;
    path?:          Category[];
    relevance:      number;
}

export enum CategoryDescription {
    Badezimmer = "Badezimmer",
    Badtextilien = "Badtextilien",
    Handtücher = "Handtücher",
    WohnenLifestyle = "Wohnen & Lifestyle",
}

export enum ProductType {
    Offer = "offer",
    Product = "product",
}

export interface ExternalProductIDS {
    asin: string[];
    gtin: string[];
    mpn:  string[];
    pzn:  any[];
}

export interface Image {
    purpose: Purpose;
    url:     string;
}

export enum Purpose {
    Default = "default",
    Main = "main",
}

export interface Marketplace {
    seller_name: null;
}

export interface Method {
    description: PaymentMethodDescription;
}

export enum PaymentMethodDescription {
    AmazonPay = "Amazon Pay",
    AmericanExpress = "American Express",
    Andere = "Andere",
    Barnachnahme = "Barnachnahme",
    Barzahlen = "Barzahlen",
    Dpd = "DPD",
    EurocardMastercard = "Eurocard/Mastercard",
    Finanzkauf = "Finanzkauf",
    Giropay = "Giropay",
    Gls = "GLS",
    GooglePay = "Google Pay",
    Hermes = "Hermes",
    International = "International",
    Lastschrift = "Lastschrift",
    PayPal = "PayPal",
    Paydirekt = "paydirekt",
    PostDHL = "Post/DHL",
    Rechnung = "Rechnung",
    Skrill = "Skrill",
    Sofortüberweisung = "Sofortüberweisung",
    Spedition = "Spedition",
    TransOFlex = "Trans-o-Flex",
    UPS = "UPS",
    VersandNachA = "Versand nach A",
    VersandNachCH = "Versand nach CH",
    Visa = "Visa",
    Vorkasse = "Vorkasse",
}

export interface Price {
    max?:      Max;
    min?:      Max;
    amount?:   number;
    currency?: Currency;
    shipping?: number;
    total?:    number;
}

export enum Currency {
    Eur = "EUR",
}

export interface Max {
    amount:   number;
    currency: Currency;
}

export interface PriceInfo {
    price:        Max;
    total:        Max;
    unit_pricing: UnitPricing;
}

export interface UnitPricing {
    base_measure_unit:  null;
    base_measure_value: number;
    measure_unit:       null;
    measure_value:      null;
    text:               null;
}

export interface Shipping {
    code:  string;
    price: Max;
    text:  string;
}

export interface Shop {
    cpc:              Max;
    id:               number;
    logo_url:         string;
    name:             string;
    payment_methods:  Method[];
    shipping_methods: Method[];
    url:              string;
    user_reviews:     TestReports;
}

export interface TestReports {
    count:  number;
    rating: number | null;
}

export interface Voucher {
    text: string;
}

export interface RelatedRequestData {
    data: KelkooOffer[];
}

export interface KelkooOffer {
    offerId:            string;
    offerType:          string;
    title:              string;
    description:        string;
    price:              number;
    priceWithoutRebate: number;
    rebatePercentage:   number;
    deliveryCost:       number;
    totalPrice:         number;
    unitPrice:          null;
    currency:           string;
    availabilityStatus: string;
    timeToDeliver:      string;
    code:               KelkooCode;
    images:             KelkooImage[];
    goUrl:              string;
    estimatedCpc:       number;
    estimatedMobileCpc: number;
    brand:              KelkooBrand;
    merchant:           KelkooCategory;
    category:           KelkooCategory;
    matched?:           Record<string, number>;
    maxMatch?:          string;
}

export interface KelkooBrand {
    name: string;
}

export interface KelkooCategory {
    id: number;
    name: string;
}

export interface KelkooCode {
    ean: string;
}

export interface KelkooImage {
    url:     string;
    zoomUrl: string;
}

export interface CategoryFilters {
    id:                 number;
    query:              string;
    display_term:       string;
    kelkoo_category_id: number;
    categories:         number[];
    images:             string[];
    treeFilter:         TreeFilter;
}

export interface TreeFilter {
    children:            Child[];
    description:         string;
    document_types:      DocumentType[];
    id:                  string;
    image_url:           string;
    is_leaf:             boolean;
    parent_id:           null;
    relevance:           number;
    filter_parameter:    string;
    is_range_selectable: boolean;
}

export interface Child {
    children:       Child[]
    description:    string
    document_types: DocumentType[]
    id:             number
    image_url:      string
    is_leaf:        boolean
    parent_id:      number
    relevance:      number
    selected:       boolean
}

export enum DocumentType {
    Offer = "offer",
    Product = "product",
}

export const EFlagOptions = {
    DE: 'de',
    US: 'en',
    ES: 'es',
    FR: 'fr',
    IT: 'it',
}

export interface ParamsPayload {
    term?: string,
    filter?: SelectedFilters,
    meta?: Meta,
    sort: string|null,
}
