var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "transparent", attrs: { flat: "" } },
    [
      _c("v-img", {
        staticClass: "white p-card",
        class: { "lg-and-up--first-card": _vm.currentPageIndex === 0 },
        attrs: {
          contain: "",
          src:
            _vm.product.images.length > 0
              ? _vm.product.images[0].url
              : "/noimage.svg",
          alt: _vm.product.description,
        },
      }),
      _vm._v(" "),
      _c(
        "v-card-title",
        {
          staticClass:
            "px-0 text-body-1 justify-space-between font-weight-bold text--nobreak pb-5 d-flex align-items-stretch",
        },
        [
          _c("span", { staticClass: "text-clip flex-1" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.stripHtml(_vm.product.name)) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _vm.product.price
            ? _c("span", { staticClass: "font-weight-bold primary--text" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.product.price.amount
                        ? _vm.priceFormatter.format(
                            _vm.product.price.amount / 100
                          )
                        : _vm.$t("product.from") +
                            " " +
                            _vm.priceFormatter.format(
                              _vm.product.price.min.amount
                            )
                    ) +
                    "\n"
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("v-card-subtitle", { staticClass: "px-0 pb-2 text-clip mht" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.stripHtml(_vm.product.description)) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "v-card-actions",
        { staticClass: "pa-0" },
        [
          _vm.isOffer
            ? _c(
                "v-btn",
                {
                  staticClass: "font-weight-bold px-0 text-capitalize",
                  attrs: {
                    large: "",
                    block: "",
                    color: "primary",
                    target: "_blank",
                    href: _vm.product.clickout_url,
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("product.moreDetails")) +
                      "\n            "
                  ),
                ]
              )
            : _c(
                "v-btn",
                {
                  staticClass: "font-weight-bold px-0 text-capitalize",
                  attrs: { large: "", block: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.openProductOverlay(_vm.product)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("product.comparePrice")) +
                      "\n            "
                  ),
                ]
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }