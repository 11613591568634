var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "transparent pa-2", attrs: { flat: "" } },
    [
      _c("v-img", {
        staticClass: "white",
        attrs: {
          contain: "",
          "min-height": "243px",
          src: _vm.product.images[0].url,
          alt: _vm.product.description,
        },
      }),
      _vm._v(" "),
      _c(
        "v-card-title",
        {
          staticClass:
            "px-0 text-body-1 justify-space-between font-weight-bold text--nobreak pb-5 d-flex align-items-stretch",
        },
        [
          _c("span", { staticClass: "text-clip flex-1" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.stripHtml(_vm.product.title)) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _vm.product.price
            ? _c("span", { staticClass: "font-weight-bold primary--text" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.priceFormatter.format(_vm.product.price)) +
                    "\n            "
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("v-card-subtitle", { staticClass: "px-0 pb-0 text-clip mht" }, [
        _vm._v(
          "\n            " +
            _vm._s(_vm.stripHtml(_vm.product.description)) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "v-card-subtitle",
        { staticClass: "pa-0 pb-2 text-clip font-weight-bold primary--text" },
        [
          _vm._v(
            "\n            " +
              _vm._s(_vm.stripHtml(_vm.product.merchant.name)) +
              "\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        { staticClass: "pa-0" },
        [
          _c(
            "v-btn",
            {
              staticClass: "d-flex font-weight-bold px-0 text-capitalize",
              attrs: {
                large: "",
                block: "",
                color: "primary",
                href: _vm.product.goUrl,
                target: "_blank",
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("product.toShop")) +
                  "\n            "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }