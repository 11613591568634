import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";
import {AvailableFilter, Meta, Product, RequestData, SearchProduct, SelectedFilters} from "~/types";
import axios, {AxiosResponse} from "axios";

const VuexModule = createModule({
    namespaced: "search",
    strict: false,
    target: "nuxt",
})

export class SearchStore extends VuexModule {

    searchProductsLoading = false;

    products: Product[] = [];

    title: string = '';

    description: string = '';

    meta: Meta = {
        total:          0,
        offset:         0,
        page:           1,
        itemsPerPage:   10,
    }

    availableFilters: AvailableFilter[] = []

    images: string[] = [];

    @action async fetchSearch(payload: { term: string, filter: SelectedFilters, meta: Meta, sort: null|string, payloadParams?: Record<string, string> })
    {
        let url, queryParams
        const baseUrl = 'http://staging.discounthero.org/api/search?'
        // const baseUrl = 'http://local.discounthero.com/api/search?'
        if ( payload.payloadParams ) {
            queryParams = new URLSearchParams(payload.payloadParams);
        } else {
            const params = JSON.parse(JSON.stringify(payload.filter))
            const processedFilter:Record<string, string> = {
                page: payload.meta.page as unknown as string,
                itemsPerPage: payload.meta.itemsPerPage as unknown as string,
            }
            Object.entries(params).forEach(([key, value], index) => {
                if ( Array.isArray(value) && value.length > 0 ) processedFilter[key] = value.join(',')
                if ( !Array.isArray(value) && value.range[0] === value.range[1] ) return
                if ( !Array.isArray(value) && !value.selected ) return
                if ( !Array.isArray(value) ) {
                    if ( key === 'filter:price' ) {
                        processedFilter['price'] = value.range.join('..')
                    } else processedFilter[key] = value.range.join('..')
                }
            })
            if ( payload.term ) processedFilter.q = payload.term
            if ( payload.sort ) processedFilter.sort = payload.sort
            this.searchProductsLoading = true
            queryParams = new URLSearchParams(processedFilter)
        }
        url = baseUrl+queryParams
        return axios.get(url)
            .then( (r:AxiosResponse<RequestData>) => {
                this.products = r.data.products
                this.meta = r.data.meta
                this.searchProductsLoading = false
                return r.data
            })
            .catch(err => {
                this.searchProductsLoading = false;
                console.error(err.response);
                return err.response
            });
    }
}



