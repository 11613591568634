var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.isActive,
        "max-width": "1100px",
        width: "calc(100%-48px)",
        "content-class": "relative",
      },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "mt-0 z-10",
          attrs: {
            absolute: "",
            right: "",
            top: "",
            icon: "",
            large: "",
            color: "primary",
          },
          on: {
            click: function ($event) {
              _vm.isActive = ""
            },
          },
        },
        [
          _c("v-icon", [
            _vm._v("\n            " + _vm._s(_vm.mdiClose) + "\n        "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.value
        ? _c(
            "v-container",
            { staticClass: "pa-0 max-90vh", attrs: { fluid: "" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t(_vm.value)) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("v-card-text", {
                    domProps: { innerHTML: _vm._s(_vm.pageContent) },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }