var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "1100px",
        width: "calc(100%-48px)",
        "content-class": "relative",
      },
      model: {
        value: _vm.isActive,
        callback: function ($$v) {
          _vm.isActive = $$v
        },
        expression: "isActive",
      },
    },
    [
      _c(
        "v-btn",
        {
          staticClass: "mt-0 z-10",
          attrs: {
            absolute: "",
            right: "",
            top: "",
            icon: "",
            large: "",
            color: "primary",
          },
          on: {
            click: function ($event) {
              _vm.isActive = false
            },
          },
        },
        [
          _c("v-icon", [
            _vm._v("\n            " + _vm._s(_vm.mdiClose) + "\n        "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.activeProduct
        ? _c(
            "v-container",
            { staticClass: "pa-0 max-90vh", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "white pa-sm-8 pa-xs-0 relative",
                  attrs: { justify: "center", "no-gutters": "" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "transparent", attrs: { flat: "" } },
                        [
                          _c("v-img", {
                            staticClass: "white",
                            attrs: {
                              "max-height": "475px",
                              contain: "",
                              src:
                                _vm.activeProductMainOffer &&
                                _vm.activeProductMainOffer.images.length > 0
                                  ? _vm.activeProductMainOffer.images[0].url
                                  : _vm.activeProduct.images[0].url,
                              alt: _vm.activeProduct.description,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "transparent px-2 px-md-0",
                          attrs: { flat: "" },
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "px-0 text-h4 font-weight-medium pb-lg-8 pb-xs-3 pb-sm-6 text--nobreak",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.stripHtml(_vm.activeProduct.name)
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-subtitle",
                            {
                              staticClass:
                                "px-0 primary--text text-clip mht pb-lg-4 pb-xs-2 pb-sm-3",
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.activeProduct.category.description
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.activeProductMainOffer
                            ? [
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass:
                                      "px-0 text-h4 font-weight-bold primary--text pt-0 pb-lg-8 pb-xs-3 pb-sm-6",
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.priceFormatter.format(
                                            _vm.activeProductMainOffer
                                              .price_info.price.amount
                                          )
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-decoration-none",
                                    attrs: {
                                      href: _vm.activeProductMainOffer
                                        .clickout_url,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "pb-5" },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "4" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-block grey-overlay-text--text",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(_vm.$t("shop")) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("v-img", {
                                              attrs: {
                                                "max-height": "48px",
                                                src: _vm.activeProductMainOffer
                                                  .shop.logo_url,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("v-col", { attrs: { cols: "4" } }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block grey-overlay-text--text",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(_vm.$t("shipping")) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block pt-3 body-1 font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.priceFormatter.format(
                                                      _vm.activeProductMainOffer
                                                        .shipping.price.amount
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("v-col", { attrs: { cols: "4" } }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block grey-overlay-text--text",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.$t("shippingTime")
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block pt-3 body-1 font-weight-bold",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.activeProductMainOffer
                                                      .shipping.text
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "d-flex font-weight-bold px-0 text-capitalize",
                                    attrs: {
                                      large: "",
                                      color: "primary",
                                      href: _vm.activeProductMainOffer
                                        .clickout_url,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.$t("product.toShop")) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            : [
                                _vm._v(
                                  "\n\n                            " +
                                    _vm._s(
                                      _vm.$t(
                                        "product.overlay.noOffersAvailable"
                                      )
                                    ) +
                                    "\n\n                    "
                                ),
                              ],
                          _vm._v(" "),
                          _vm.activeProductOtherOffers.length > 0
                            ? [
                                _c("v-card-text", { staticClass: "px-0" }, [
                                  _c(
                                    "span",
                                    { staticClass: "secondary--text text-h6" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "product.overlay.offerOther1"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "primary--text text-h6" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.$t(
                                              "product.overlay.offerOther2"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "v-row",
                                  { staticClass: "pb-5 px-2 px-md-0" },
                                  _vm._l(
                                    _vm.activeProductOtherOffers,
                                    function (productOffer) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: productOffer.id,
                                          staticClass: "pa-1",
                                          attrs: { cols: "3" },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "text-decoration-none pa-2",
                                              attrs: {
                                                tile: "",
                                                outlined: "",
                                                href: productOffer.clickout_url,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c("v-img", {
                                                attrs: {
                                                  "max-height": "48px",
                                                  src: productOffer.shop
                                                    .logo_url,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "text-decoration-none",
                                              attrs: {
                                                href: productOffer.clickout_url,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "d-block pt-3 body-1 font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.priceFormatter.format(
                                                          productOffer
                                                            .price_info.price
                                                            .amount
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.relatedLoading
                ? [
                    _c(
                      "v-row",
                      { staticClass: "grey-bg py-2 px-sm-8 px-xs-0 ma-0" },
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-skeleton-loader", {
                              attrs: { type: "heading" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { staticClass: "grey-bg pt-0 px-sm-8 px-xs-0 ma-0" },
                      [
                        _c(
                          "v-col",
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "mb-2",
                              attrs: { type: "image" },
                            }),
                            _vm._v(" "),
                            _c("v-skeleton-loader", {
                              attrs: { type: "paragraph, button" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "mb-2",
                              attrs: { type: "image" },
                            }),
                            _vm._v(" "),
                            _c("v-skeleton-loader", {
                              attrs: { type: "paragraph, button" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "mb-2",
                              attrs: { type: "image" },
                            }),
                            _vm._v(" "),
                            _c("v-skeleton-loader", {
                              attrs: { type: "paragraph, button" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          [
                            _c("v-skeleton-loader", {
                              staticClass: "mb-2",
                              attrs: { type: "image" },
                            }),
                            _vm._v(" "),
                            _c("v-skeleton-loader", {
                              attrs: { type: "paragraph, button" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm.filteredRelatedOffers.length > 0 && !_vm.relatedLoading
                ? [
                    _c(
                      "v-row",
                      {
                        staticClass: "grey-bg pa-sm-8 pa-xs-0 pb-xs-0 pb-sm-0",
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c("v-col", [
                          _c(
                            "span",
                            { staticClass: "secondary--text text-h4" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t("product.overlay.relatedOther1")
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "primary--text text-h4" }, [
                            _vm._v(
                              "\n                        " +
                                _vm._s(
                                  _vm.$t("product.overlay.relatedOther2")
                                ) +
                                "\n                    "
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      {
                        staticClass: "grey-bg pa-sm-8 pa-xs-0",
                        attrs: { "no-gutters": "" },
                      },
                      _vm._l(
                        _vm.filteredRelatedOffers,
                        function (relatedOffer) {
                          return _c(
                            "v-col",
                            { key: relatedOffer.offerId, attrs: { cols: "3" } },
                            [
                              _c("related-offer-card", {
                                attrs: { product: relatedOffer },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }