import {KelkooOffer, ParamsPayload} from "~/types";

export function similarity(s1, s2) {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
        longer = s2;
        shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength == 0) {
        return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

export function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    const costs = [];
    for (let i = 0; i <= s1.length; i++) {
        let lastValue = i;
        for (let j = 0; j <= s2.length; j++) {
            if (i == 0)
                costs[j] = j;
            else {
                if (j > 0) {
                    let newValue = costs[j - 1];
                    if (s1.charAt(i - 1) != s2.charAt(j - 1))
                        newValue = Math.min(Math.min(newValue, lastValue),
                            costs[j]) + 1;
                    costs[j - 1] = lastValue;
                    lastValue = newValue;
                }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
    }
    return costs[s2.length];
}

export function wordMatch(s1: string, s2: string): number[]
{
    const s1Split = s1.split(' ').map(w => w.toLowerCase())
    const s2Split = s2.split(' ').map(w => w.toLowerCase())
    const smaller = s1Split.length > s2Split.length ? s2Split : s1Split
    const bigger = s1Split.length < s2Split.length ? s2Split : s1Split
    const totalWordsCount = smaller.length
    let matched = 0
    smaller.forEach( word => {
        if (bigger.includes(word)) {
            matched++
        }
    })
    return [ matched, totalWordsCount ]
}

export function stripHtml(input: string): string
{
    const strippedString = input.replace(/(<([^>]+)>)/gi, "")
    return strippedString
}

export function matchOffers(allOffers: KelkooOffer[]): KelkooOffer[]
{
    let offers = []
    let availableOffers = allOffers
    availableOffers.forEach( (ko) => {
        availableOffers.forEach( (oko) => {
            if (ko.offerId !== oko.offerId ) {
                const match = wordMatch(ko.title, oko.title)
                const matchPct = match[0] / match[1] as number
                if ( matchPct > 0.66 )  {
                    oko.matched = oko.matched || {}
                    oko.matched[ko.offerId] = matchPct
                    // console.log( 'match!', ko.offerId, wordMatch(ko.title, oko.title), ko.title, oko.title)
                } else {
                    // console.log( 'no match!', ko.offerId, wordMatch(ko.title, oko.title), ko.title, oko.title)
                }
            }
        })
    })
    availableOffers.forEach( ao => {
        if (ao.matched) {
            const keys = Object.keys(ao.matched).sort()

            const matches = keys.map( k => ao.matched![k])

            const max = Math.max(...matches)

            const index = matches.indexOf(max)

            ao.maxMatch = keys[index]
        }
    })

    let maxMatches = availableOffers.map(o => o.maxMatch)
    const counts = {}

    for (const match of maxMatches) {
        counts[match] = counts[match] ? counts[match] + 1 : 1
    }

    const matchesSorted = Object.keys(counts).sort((a,b) => counts[b]-counts[a])

    matchesSorted.forEach( m => {
        let matchedOffers = availableOffers.filter( el => el.maxMatch === m )
        if (matchedOffers.length) {
            availableOffers = availableOffers.filter( el => !(el.maxMatch === m) )
            const originalOffer = availableOffers.find(el => el.offerId)
            const originalOfferIndex = availableOffers.indexOf(originalOffer)
            availableOffers.splice(originalOfferIndex, 1)
            matchedOffers.push(originalOffer)
            matchedOffers.sort((a,b) => a.price - b.price)
            // console.log(matchedOffers)
            offers.push(matchedOffers[0])
        }
    })
    availableOffers.forEach(ao => {
        if (!matchesSorted.includes(ao.offerId)) {
            offers.push(ao)
        }
    })
    return offers
}

export function payloadToUrlParams(payload: ParamsPayload): Record<string, string>
{
    let urlParams = {}
    Object.entries(payload).forEach( ( [key, value], index) => {
        if (Object.entries(payload).length === 0) return {}
        switch(key) {
            case 'term': {
                if ( value ) urlParams['q'] = value
                break;
            }
            case 'filter': {
                Object.entries(value).forEach(([ filterKey, filterValue], filterIndex) => {
                    if ( Array.isArray(filterValue) && filterValue.length > 0 ) {
                        urlParams['f_'+filterKey] = filterValue.join(',')
                    }
                    if ( !Array.isArray(filterValue) && filterValue.range[0] === filterValue.range[1] ) return
                    if ( !Array.isArray(filterValue) && !filterValue.selected ) return
                    if (filterKey === 'filter:price' ) {
                        filterKey = 'price'
                        !Array.isArray(filterValue) ? filterValue.range = filterValue.range.map( n => n / 100) : filterValue
                    }
                    if ( !Array.isArray(filterValue) ) urlParams['f_'+filterKey] = filterValue.range.join('..')
                })
                break;
            }
            case 'meta': {
                urlParams['m_p'] = value.page as unknown as string
                urlParams['m_ipp'] = value.itemsPerPage as unknown as string
                break;
            }
            case 'sort': {
                if (value) urlParams['sort'] = value
                break;
            }
            default: {
                console.log('it\'s a default')
                break;
            }
        }
    })
    return urlParams
}

export function urlParamsToPayloadParams(params: Record<string, string>): Record<string, string>
{
    let payloadParams = {}
    Object.entries(params).forEach( ( [key, value], index) => {
        if (Object.entries(params).length === 0) return {}
        if ( key === 'q' ) {
            if ( value ) payloadParams[key] = value
        } else if ( key.indexOf('f_') >= 0 ) {
            const newKey = key.replace('f_', '')
            payloadParams[newKey] = value
        } else if ( key.indexOf('m_') >= 0 ) {
            const newKey = key === 'm_p' ? 'page' : 'itemsPerPage'
            payloadParams[newKey] = value
        } else if ( key === 'sort' ) {
        }
    })

    return payloadParams
}

export async function download(url: string)
{
    // const file = URL.createObjectURL(url)
    // fetch(url, { mode: 'no-cors' })
    //     .then(res => {
    //         console.log('res: ', res)
    //         return res.blob()
    //     })
    //     .then(blob => {
    //         const link = document.createElement("a");
    //         link.href = URL.createObjectURL(blob);
    //         link.download = url;
    //         link.click();
    //         // console.log('blob: ', blob.type)
    //         // console.log('blob: ', blob)
    //     })

    // fetch(url)
    //     .then(resp => resp.blob())
    //     .then(blobobject => {
    //         const blob = window.URL.createObjectURL(blobobject);
    //         const anchor = document.createElement('a');
    //         anchor.style.display = 'none';
    //         anchor.href = blob;
    //         anchor.download = "name.png"";
    //         document.body.appendChild(anchor);
    //         anchor.click();
    //         window.URL.revokeObjectURL(blob);
    //     })
    //     .catch(() => console.log('An error in downloadin gthe file sorry'));
}