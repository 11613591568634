import { createModule, mutation, action, extractVuexModule } from "vuex-class-component";
import {Meta, Product, RelatedRequestData, RequestData, SelectedFilters, TopProduct} from "~/types";
import axios, {AxiosResponse} from "axios";

const VuexModule = createModule({
    namespaced: "products",
    strict: false,
    target: "nuxt",
})

export class ProductsStore extends VuexModule {

    topProducts: Product[] = [];
    topProductsLoading = false

    offersLoading = false

    relatedLoading = false

    categoryProducts: Record<string, TopProduct[]> = {};

    searchProducts: TopProduct[] = [];

    // @action async getTopProducts()
    // {
    //     this.topProductsLoading = true;
    //     return axios.get('api/products/top')
    //         .then( r => {
    //             this.topProducts = r.data;
    //             this.topProductsLoading = false;
    //             return r.data;
    //         })
    //         .catch(err => {
    //             this.topProductsLoading = false;
    //             console.error(err.response);
    //             return err.response
    //         });
    //     // Fetch Top Products from API
    //     // console.log();
    //     // const response = await this.$axios.get('api/products/top')
    //     // const products = await response.data
    //     // return products
    // }
    // @action async nuxtServerInit()
    // {
    //     const client = axios.create({
    //         baseUrl: process.env.BASE_URL
    //     });
    //     this.topProductsLoading = true;
    //     return axios.get('api/products/top')
    //         .then( r => {
    //             this.topProducts = r.data;
    //             this.topProductsLoading = false;
    //             return r.data;
    //         })
    //         .catch(err => {
    //             this.topProductsLoading = false;
    //             console.error(err.response);
    //             return err.response
    //         });
    //     // Fetch Top Products from API
    //     // console.log();
    //     // const response = await this.$axios.get('api/products/top')
    //     // const products = await response.data
    //     // return products
    // }

    @action async fetchOffers(payload: { products: string[]})
    {
            this.offersLoading = true
            const formData = new FormData()
            payload.products.forEach( (pid:string) => {
                formData.append('products[]', pid);
            })
            let url = 'http://staging.discounthero.org/api/offers'
            // let url = 'http://local.discounthero.com/api/offers'
            return axios.post(url, formData)
                .then( (r:AxiosResponse<RequestData>) => {
                    this.offersLoading = false
                    // console.log(r.data)
                    return r;
                })
                .catch(err => {
                    this.offersLoading = false
                    console.error(err.response)
                    return err.response
                })
    }
    @action async relatedOffers(payload: { product: string, price: number })
    {
        this.relatedLoading = true

        const queryParams = new URLSearchParams(payload as Record<string, string>)

        let url = 'http://staging.discounthero.org/api/related'+'?'+queryParams
        // let url = 'http://local.discounthero.com/api/related'+'?'+queryParams
        return axios.get(url)
            .then( (r:AxiosResponse<RelatedRequestData>) => {
                this.relatedLoading = false
                // console.log(r.data)
                return r;
            })
            .catch(err => {
                this.relatedLoading = false
                // console.error(err.response)
                return err.response
            });
    }
}



