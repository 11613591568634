

// store.vuex.ts
import { extractVuexModule, createProxy } from "vuex-class-component";
import Vuex from 'vuex';
import { ProductsStore } from "~/store/products";
import { CategoryStore } from "~/store/category";
import { SearchStore } from "~/store/search";

export const store = new Vuex.Store({
    modules: {
        ...extractVuexModule( ProductsStore ),
        ...extractVuexModule( CategoryStore ),
        ...extractVuexModule( SearchStore ),
    }
})

// Creating proxies.
export const vxm = {
    products:   createProxy(    store,  ProductsStore   ),
    category:   createProxy(    store,  CategoryStore   ),
    search:     createProxy(    store,  SearchStore     ),
}